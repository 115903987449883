const ACTIONS = {
  SUBSTRACT: 'substract',
  ADD: 'add',
};

const encryptDate = (date) => {
  if (!date) {
    return null;
  }
  const ten = 10;
  const zero = 0;
  const two = 2;

  if (date.length === ten) {
    const label = '**';
    const slash = '/';
    const split = date.split('-');
    const year = `${split[zero].substring(zero, two)}${label}`;
    const month = split[1];
    return `${label}${slash}${month}${slash}${year}`;
  }
  return date;
};

export { ACTIONS, encryptDate };
