import {
  BooleanInput,
  DateInput,
  Loading,
  TextInput,
  useTranslate,
} from 'react-admin';
import React from 'react';
import Box from '@material-ui/core/Box';
import { useLoading } from 'ra-core';
import { useForm } from 'react-final-form';
import UserValidation from './UserValidation';
import EditUserBtn from './buttons/EditUserBtn';
import DeleteBtn from './buttons/DeleteBtn';


const UserDetailTabForm = () => {
  const translate = useTranslate();
  const loading = useLoading();
  const { change } = useForm();
  if (loading) {
    return <Loading />;
  }
  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <TextInput
        source="username"
        validate={UserValidation.validateUsername}
        resettable
        onClick={() => { change('username', ''); }}
        label={translate('user.edit.username')}
      />
      <TextInput
        source="name"
        validate={UserValidation.validateName}
        onClick={() => { change('name', ''); }}
        resettable
        label={translate('user.edit.name')}
      />
      <TextInput
        source="lastName"
        onClick={() => { change('lastName', ''); }}
        resettable
        label={translate('user.edit.lastname')}
      />
      <BooleanInput
        source="active"
        label={translate('user.edit.activated')}
      />
      <BooleanInput
        source="isAdmin"
        label={translate('user.edit.isAdmin')}
      />
      <BooleanInput
        source="communicationsPolicy"
        label={translate('user.edit.communication')}
      />
      <BooleanInput
        source="shuffleConnectionPolicy"
        label={translate('user.edit.shuffleConnection')}
      />
      <BooleanInput
        source="conditions"
        disabled
        label={translate('user.edit.conditions')}
      />
      <TextInput
        source="legacyContext.externalUserId"
        disabled
        label={translate('user.edit.externalId')}
      />
      <TextInput
        source="legacyContext.monavisId"
        disabled
        label={translate('user.edit.monavisId')}
      />
      <TextInput
        source="registeredFromApp"
        disabled
        label={translate('user.edit.registeredFromApp')}
      />
      <DateInput
        source="createdAt"
        disabled
        label={translate('user.edit.createdAt')}
      />
      <DateInput
        source="updatedAt"
        disabled
        label={translate('user.edit.updatedAt')}
      />
      <DateInput
        source="activatedAt"
        disabled
        label={translate('user.edit.activatedAt')}
      />
      <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <EditUserBtn
          customLoad={() => {}}
          customProfile={() => {}}
          errorHomeCom={() => {}}
        />
        <DeleteBtn
          customLoad={() => {}}
          errorHomeCom={() => {}}
        />
      </Box>
    </Box>
  );
};

export default UserDetailTabForm;
