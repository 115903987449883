import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import { Slider } from '@material-ui/core';
import LoopIcon from '@material-ui/icons/Loop';

export const promotionFormStyles = makeStyles(theme => ({
  formControlFields: {
    width: '100%',
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  helpLabel: {
    color: 'black',
    fontSize: 14,
  },
  helpContent: {
    color: 'black',
    fontSize: 14,
    fontWeight: 'bold',
    marginLeft: 10,
  },
  formControl: {
    boxSizing: 'border-box',
    width: '100%',
    margin: '0 0 20px 0',
    marginBottom: '20px',
    padding: '10px 50px 50px 20px',
    fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif',

    [theme.breakpoints.down('md')]: {
      padding: '0',
    },
  },
  form: {
    width: '100%',
  },
  mainRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    margin: '20px 0',
    width: '100%',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  rowCol: {
    width: '50%',
    flex: '0 1 auto',

    '&:first-child': {
      paddingRight: '8px',
    },
    '&:last-child': {
      paddingLeft: '8px',
    },

    [theme.breakpoints.down('md')]: {
      width: '100%',

      '&:first-child': {
        paddingRight: '0',
      },
      '&:last-child': {
        paddingLeft: '0',
      },
    },
  },
  fullRowCol: {
    width: '100%',
    flex: '0 1 auto',
  },
  columnLeft: {
    width: '50%',
    padding: '20px 20px 0 20px',
    paddingRight: '50px',
  },
  columnRight: {
    width: '50%',
    padding: '20px 20px 0 20px',
  },
  normalBox: {
    height: '100px',
    marginBottom: '20px',
    display: 'flex',
    padding: '10px',
    fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif',
  },
  normalBoxColumn: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif',
  },
  normalBoxLite: {
    marginBottom: '10px',
    display: 'flex',
    fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif',
  },
  normalBoxLiteCentered: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    gap: '0 10px',
    fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif',

    '& svg': {
      width: '30px',
      height: '30px',
    },
  },
  normalBoxLeft: {
    width: '50%',
    paddingRight: '10px',
  },
  normalBoxRight: {
    width: '50%',
    paddingLeft: '10px',
  },
  normalBoxFull: {
    width: '100%',
    padding: '0 10px',
    boxSizing: 'border-box',
  },
  eanBox: {
    minHeight: '150px',
    padding: '10px',
    overflowY: 'auto',
    borderRadius: '3px',
  },
  bigBox: {
    minHeight: '100px',
    marginBottom: '0',
    padding: '10px',
  },
  numInput: {
    width: '100%',
  },
  imageBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '580px',
    marginBottom: '20px',
    padding: '20px 10px 10px 10px',
    margin: '20px 20px 0 20px',
  },
  sectionTitle: {
    backgroundColor: '#e4e8f2',
    width: '100%',
    fontSize: '1.2em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    padding: '10px 5px',
    fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif',
  },
  iteratorFormItem: {
    '& > li': {
      borderBottom: 'none',
    },
  },
  fixedSaveButton: {
    backgroundColor: '#c9d2e4',
    position: 'fixed',
    bottom: '0',
    width: 'calc(100% - 272px)',
    zIndex: '2',

    [theme.breakpoints.down('md')]: {
      left: 0,
      width: '100%',
    },
  },
  buttonContainer: {
    width: '50%',
    paddingLeft: '10px',
    paddingTop: '25px',
  },
  retailers: {
    height: '115px',
    marginBottom: '20px',
    display: 'flex',
    padding: '10px',
    fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif',
  },
  retailerStockInput: {
    margin: 'unset',
    padding: 'unset',
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },

    '& .MuiInputBase-input': {
      textAlign: 'center',
    },

    '& .MuiFormHelperText-root': {
      display: 'none',
    },

    '& .MuiOutlinedInput-input': {
      padding: '6px 4px',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, .08)',
    },
  },
  tableTitle: {
    fontWeight: 'bold',
  },
  tableGeneral: {
    '& .MuiTableCell-root': {
      padding: '4px 16px',
    },
  },
  totalsRow: {
    '& .MuiTableCell-root': {
      padding: '20px 16px 4px 16px',
      fontWeight: 'bold',
      fontSize: '16px',
    },
  },
  hideHelperText: {
    '& .MuiFormHelperText-root': {
      display: 'none',
    },
  },
  ageRangeContainer: {
    width: 'auto',
    maxWidth: '40%',
    margin: '40px 0 0 0',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    alignContent: 'center',
    gap: '0 20px',
  },
  sliderContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px 0',
  },
  errorText: {
    fontSize: '11px',
  },
  sectionWrapper: {
    boxSizing: 'border-box',
    margin: '15px 0 25px',
  },
  childrenAgeRangeContainer: {
    width: 'auto',
    maxWidth: '40%',
    margin: '20px 0 0 0',
  },
  sectionTitleWrap: {
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    alignContent: 'center',
    gap: '0 20px',
  },
  mainRowGap: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    alignContent: 'center',
    gap: '20px 40px',
    margin: '20px 0',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  mainRowCol: {
    width: '350px',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  quillSticky: {
    '& .ql-container.ql-snow': {
      height: 'auto',
    },
    '& .ql-editor': {
      maxHeight: '65vh',
      overflowY: 'scroll',
    },
  },
}));

export const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(0, 0, 0, .05)',
    },
  },
}))(TableRow);

export const StyledSlider = withStyles(() => ({
  root: {
    '&.MuiSlider-colorSecondary': {
      color: '#f44336',
    },
  },
}))(Slider);

export const StyledRefreshIcon = withStyles(() => ({
  root: {
    cursor: 'pointer',
  },
}))(LoopIcon);
