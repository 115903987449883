import gql from 'graphql-tag';

import { buildFieldsGraphQL, buildUserFilterInput, buildUserOrderInput } from '../../lib/utils';

export const USER_BUSINESS_DOMAIN_NAME = 'user';
const generarCadena = () => {
  const caracteres = 'abcdefghijklmnopqrstuvxyzw';
  const numeros = '0123456789';
  let cadena = '';
  cadena += caracteres.charAt(Math.floor(Math.random() * caracteres.length)).toUpperCase();
  /* eslint-disable */
  for (let i = 0; i < 4; i++) {
    cadena += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
  }

  for (let i = 0; i < 5; i++) {
    cadena += numeros.charAt(Math.floor(Math.random() * numeros.length));
  }
  /* eslint-enable */

  return cadena;
};

const ROLES = {
  USER: 'USER',
  ADMIN: 'ADMIN',
  MOBILE: 'MOBILE',
};

const defaultFields = {
  id: true,
  username: true,
  name: true,
  lastName: true,
  active: true,
  activatedAt: true,
  telephone: true,
  createdAt: true,
  updatedAt: true,
  imageUrl: true,
  conditions: true,
  communicationsPolicy: true,
  shuffleConnectionPolicy: true,
  dataPolicy: true,
  role: true,
  registeredFromApp: true,
  legacyContext: {
    monavisId: true,
    sazId: true,
    externalUserId: true,
  },
};

const buildQueryUsers = (raFetchType, resourceName, params) => {
  switch (raFetchType) {
    case 'GET_LIST': {
      const {
        page,
        perPage: limit,
      } = params.pagination;

      return {
        query: gql`query Users ($pagination: PaginationInput, $filter: UserFilterInput, $sort: SortInput, $search: String){
                    users (pagination:$pagination, filter: $filter, sort: $sort, search: $search) {
                     pagination {
                        count
                      }
                      results {
                          ${buildFieldsGraphQL(defaultFields)}
                      }
                    }
                  }`,
        variables: {
          filter: buildUserFilterInput(params.filter),
          sort: buildUserOrderInput(params.sort),
          search: params.filter.username,
          pagination: {
            page,
            limit,
          },
        },
        parseResponse: response => ({
          data: response.data.users.results,
          total: response.data.users.pagination.count,
        }),
      };
    }
    case 'GET_ONE': {
      return {
        query: gql`query user($id: ID!) {
                    data: user(id: $id) {
                         ${buildFieldsGraphQL(defaultFields)}
                      },
                }`,
        variables: params,
        parseResponse: response => ({
          data: {
            ...response.data.data,
            isAdmin: response.data.data.role === ROLES.ADMIN,
          },
        }),
      };
    }
    case 'CREATE': {
      return {
        query: gql`
          mutation signup($data: CreateUserInput!) {
            data: signup(user:$data) {
                 ${buildFieldsGraphQL(defaultFields)}
            }
          }
        `,
        variables: {
          data: {
            ...params.data,
            id: undefined,
            active: undefined,
            password: generarCadena(),
            conditions: true,
            dataPolicy: true,
            communicationsPolicy: false,
            shuffleConnectionPolicy: false,
            __typename: undefined,
          },
        },
        parseResponse: response => ({
          data: {
            ...response.data.data,
            isAdmin: response.data.data.role === ROLES.ADMIN,
          },
        }),
      };
    }
    case 'upload': {
      return {
        query: gql`
        mutation uploadUserImage($id: ID!, $input: UploadImageInput!, $file: Upload!) {
          data: uploadUserImage(id: $id, input: $input, file: $file) {
            url
          }
        }
      `,
        variables: params,
        parseResponse: response => ({
          data: response.data.data.url,
        }),
      };
    }
    case 'delete_image': {
      return {
        query: gql`
          mutation deleteUserImage($id: ID!) {
            data: deleteUserImage(id: $id)
          }
        `,
        variables: params,
        parseResponse: response => ({
          data: response.data.data,
        }),
      };
    }
    case 'UPDATE': {
      const {
        id,
        username,
        name,
        lastName,
        active,
        role,
        communicationsPolicy,
        telephone,
        shuffleConnectionPolicy,
      } = params.data;
      return {
        query: gql`mutation updateUser($id: ID!,$data: UpdateUserInput!) {
                          data: updateUser(id: $id,input:$data) {
                             ${buildFieldsGraphQL(defaultFields)}
                          }
                      }`,
        variables: {
          id,
          data: {
            username,
            name,
            lastName,
            active,
            telephone,
            role,
            communicationsPolicy,
            shuffleConnectionPolicy,
          },
        },
        parseResponse: response => ({
          data: {
            ...response.data.data,
            isAdmin: response.data.data.role === ROLES.ADMIN,
            id: response.data.data.key,
          },
        }),
      };
    }
    case 'DELETE': {
      return {
        query: gql`mutation Unsubscribe($input: UnsubscribeInput!) {
                      unsubscribe(input: $input) {
                          userId
                      }
        }`,
        variables: {
          input: { userId: params.id },
        },
        parseResponse: response => ({
          data: {
            ...response.data.data,
          },
        }),
      };
    }
    default:
      return false;
  }
};

export default buildQueryUsers;
