import React, { useLayoutEffect, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  Datagrid,
  useNotify,
  ListButton,
  useRedirect,
  useTranslate,
  Loading,
  TextField,
  useQuery,
} from 'react-admin';
import keyBy from 'lodash/keyBy';
import CardContent from '@material-ui/core/CardContent';
import { Box, Card, CircularProgress } from '@material-ui/core';
import Dns from '@material-ui/icons/Dns';
import moment from 'moment';
import { jobDetailStyles } from './JobDetailStyles';
import JobStatusLegend from './JobStatusLegend';

const zero = 0;
const fullLoad = 100;

const JobDetail = () => {
  const translate = useTranslate();
  const globalFont = { fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif' };
  const [resource] = useState('products');
  const notify = useNotify();
  const redirect = useRedirect();
  const { jobId } = useParams();
  const classes = jobDetailStyles();

  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [jobStatus, setJobStatus] = useState('');
  const [jobStarted, setJobStarted] = useState('');
  const [jobFinished, setJobFinished] = useState('');
  const [jobProgress, setJobProgress] = useState(zero);
  const [eanProcessed, setEanProcessed] = useState(zero);
  const [eanInProgress, setEanInProgress] = useState(zero);
  const [partsData, setPartsData] = useState(undefined);
  const [sort, setSort] = useState({ field: 'id', order: 'ASC' });


  const parsePartsData = (dataInput) => {
    const output = [];
    dataInput.forEach((item) => {
      const toPush = {
        id: item.id, key: item.key, status: item.status, errors: item.errors[zero] ? item.errors[zero] : '',
      };

      output.push(toPush);
    });

    setPartsData(output);
  };

  const ids = [jobId];
  const queryData = useQuery({
    type: 'getList',
    resource: 'jobs',
    payload: {
      pagination: {
        page: 1,
        perPage: 1,
      },
      filter: { ids },
    },
  });

  useLayoutEffect(() => {
    if (!jobId) {
      redirect('/products/jobsList');
      notify(translate('products.jobDetail.noJobId'), 'warning');
    }
  }, []);

  useEffect(() => {
    if (queryData.loading) {
      setLoading(true);
      setRefreshing(true);
    }

    if (queryData.loaded) {
      setLoading(false);
      setRefreshing(false);
    }

    if (queryData.error) {
      setLoading(false);
      setRefreshing(false);
      notify('Error loading job detail', 'warning');
    }

    if (queryData.data !== undefined) {
      const jobData = queryData.data[zero];
      const {
        parts,
        status,
        progress,
      } = jobData;
      const parsedLog = parts;
      const sumProg = progress !== null ? Math.round(parseFloat(progress) * fullLoad) : zero;
      const processed = parsedLog.filter(item => item.status === 'FINISHED');
      const inProgress = parsedLog.filter(item => item.status !== 'FINISHED');
      parsePartsData(parts);
      setJobStatus(status);
      setJobStarted(jobData.startedAt !== null ? moment(jobData.startedAt)
        .format('L H:mm:ss') : '-');
      setJobFinished(jobData.finishedAt !== null ? moment(jobData.finishedAt)
        .format('L H:mm:ss') : '-');
      setEanProcessed(processed.length);
      setEanInProgress(inProgress.length);
      setJobProgress(sumProg);
    }
  }, [queryData]);

  return (
    <Card style={{ width: '100%' }}>
      {loading && <Loading />}
      {!loading && (
      <CardContent>
        <ListButton
          style={{ marginRight: '5px' }}
          basePath={`/${resource}`}
          label="Products List"
        />
        <ListButton
          style={{ marginRight: '5px' }}
          basePath={`/${resource}/jobsList`}
          label="Jobs List"
          icon={<Dns />}
        />
        <h2 style={{ ...globalFont }}>
          {translate(
            'products.jobDetail.title',
            { id: `${jobId}` },
          )}
          {refreshing && <CircularProgress size={20} thickness={1} style={{ marginLeft: '5px' }} />}
        </h2>
        <Box />
        <JobStatusLegend />
        <Box />
        <Box className={classes.container}>
          <div className={classes.cell}>
            {translate('products.jobDetail.labels.status')} {jobStatus}
          </div>
          <div className={classes.cell}>
            {translate('products.jobDetail.labels.progress')} {jobProgress}%
          </div>
        </Box>
        <Box className={classes.container}>
          <div className={classes.cell}>
            {translate('products.jobDetail.labels.startedAt')} {jobStarted}
          </div>
          <div className={classes.cell}>
            {translate('products.jobDetail.labels.finishedAt')} {jobFinished}
          </div>
        </Box>
        <Box className={classes.container}>
          <div className={classes.cell}>
            {translate('products.jobDetail.labels.eanProcessed')} {eanProcessed}
          </div>
          <div className={classes.cell}>
            {translate('products.jobDetail.labels.eanInProgress')} {eanInProgress}
          </div>
        </Box>
        <Box />
        <h3 style={{ ...globalFont }}>{translate('products.jobDetail.labels.parts')} ({partsData.length})</h3>
        <Box />
        <Box>
          <Datagrid
            data={keyBy(partsData, 'id')}
            ids={partsData?.map(({ id }) => id)}
            currentSort={sort}
            setSort={(field, order) => setSort({ field, order })}
          >
            <TextField source="key" label="EAN" sortable={false} />
            <TextField source="status" sortable={false} />
            <TextField source="errors" sortable={false} />
          </Datagrid>
        </Box>
      </CardContent>
      )}
    </Card>
  );
};

export default JobDetail;
