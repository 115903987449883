import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import {
  useTranslate,
  Datagrid,
  List,
  SimpleList,
  TextField,
  BooleanField,
  DateField,
  Filter,
  TextInput, ListButton,
} from 'react-admin';

import Publish from '@material-ui/icons/CloudDownload';
import { LinkTextField } from '../../lib/components';

const UserFilter = ({ translate, permissions, ...props }) => (
  <Filter {...props} data-testid="user-search">
    <TextInput source="username" alwaysOn label={translate('user.filter.username')} />
    <TextInput source="legacyContext.externalUserId" alwaysOn label={translate('user.filter.external')} />
    <ListButton
      style={{ marginRight: '5px' }}
      basePath="/unsub"
      label={translate('unsub.title')}
      icon={<Publish />}
      alwaysOn
    />
  </Filter>
);
const UserList = ({ permissions, ...props }) => {
  const translate = useTranslate();
  const { basePath } = props;
  return (
    <List
      {...props}
      sort={{
        field: 'username',
        order: 'ASC',
      }}
      bulkActionButtons={false}
      exporter={false}
      filters={<UserFilter translate={translate} permissions={permissions} />}
    >
      {useMediaQuery(theme => theme.breakpoints.down('sm')) ? (
        <SimpleList
          primaryText={record => record.name}
          data-testid="user-list-mobile-data-grid"
        />
      ) : (
        <Datagrid
          rowClick="edit"
          optimized
          data-testid="user-list-data-grid"
        >
          <LinkTextField basePath={basePath} permissions={permissions} source="username" label={translate('user.list.username')} />
          <TextField source="name" sortable={false} label={translate('user.list.name')} />
          <TextField source="lastName" sortable={false} label={translate('user.list.lastName')} />
          <BooleanField source="active" sortable={false} label={translate('user.list.active')} />
          <DateField source="activatedAt" label={translate('user.list.activatedAt')} />
        </Datagrid>
      )}
    </List>
  );
};


export default UserList;
