export const PROMO_NAME_MAX_LENGTH = 100;
export const PROMO_TYPE_LENGTH = 30;
export const DESCRIPTION_MAX_LENGTH = 1000;
export const LEGAL_POLICIES_MAX_LENGTH = 700;
export const COUPON_CODE_MAX_LENGTH = 50;
export const ERROR_TEXT = 'This field is mandatory';
export const PROMO_EAN_MAX_LENGTH = 20;
export const PROMO_EAN_MAX_FIELDS = 12;
export const VALID_EAN13_LENGTH = 13;
export const FOUR = 4;
export const ONE = 1;
export const FIRST_ITEM = 0;
export const ACTIVE_STATUS = 701;
export const AUTO_PUBLISH_STATUS = 602;
export const NO_FOUND_INDEX = -1;
export const RADIX_TEN = 10;
export const ORDER_VALIDITY_LENGTH = 1000;
export const EMPTY = 0;

export const PROMOTION_INITIAL_VALUES = {
  name: '',
  type: null,
  status: false,
  description: null,
  legalText: null,
  couponCode: null,
  startDate: null,
  endDate: null,
  eans: [],
};

export const STATUS = [{
  key: 1,
  code: '001',
  status: 'New',
},
{
  key: 601,
  code: '601',
  status: 'Ready to publish manually',
}, {
  key: 602,
  code: '602',
  status: 'Ready to publish automatically',
}, {
  key: 701,
  code: '701',
  status: 'Active',
}, {
  key: 801,
  code: '801',
  status: 'Finished',
}, {
  key: 901,
  code: '901',
  status: 'Stand by',
}, {
  key: 902,
  code: '902',
  status: 'Cancelled',
}];

export const START_INDEX = 1;
export const CANCELLED = 'CANCELLED';
export const REQUESTED = 'REQUESTED';
export const EXPIRED = 'EXPIRED';
export const REDEEMED = 'REDEEMED';
export const EVALUATED = 'EVALUATED';
export const FAILED = 'FAILED';

export const ORDER_STATUS = {
  REQUESTED,
  CANCELLED,
};
export const ORDER_STATUS_FROM_REQUESTED = {
  REQUESTED,
  REDEEMED,
  CANCELLED,
};

export const ORDER_STATUS_FILTER = [
  { id: REQUESTED, name: 'Requested' },
  { id: EXPIRED, name: 'Expired' },
  { id: REDEEMED, name: 'Redeemed' },
  { id: CANCELLED, name: 'Cancelled' },
  { id: EVALUATED, name: 'Evaluated' },
  { id: FAILED, name: 'Failed' },
];

export const RATING = [{
  id: 1,
  name: '○ 1',
},
{
  id: 2,
  name: '○ 2',
},
{
  id: 3,
  name: '○ 3',
},
{
  id: 4,
  name: '○ 4',
},
{
  id: 5,
  name: '○ 5',
},
];

export const SEGMENTATION_GENDERS = [
  { id: 'MALE', name: 'Man' },
  { id: 'FEMALE', name: 'Woman' },
  { id: 'OTHER', name: 'Other' },
];

export const PROMOTION_TYPES = {
  WITH_POINTS: 'SAZ NEXT WITH POINTS',
  WITHOUT_POINTS: 'SAZ NEXT WITHOUT POINTS',
  REDEMPTION_POINT: 'WITHDRAWAL AT REDEMPTION POINT',
  HOME_DELIVERY: 'HOME DELIVERY',
  MALRG: 'MALRG',
};
