import React, { useEffect, useState } from 'react';
import {
  useDataProvider, useNotify, useRedirect, Button, useRecordContext,
} from 'react-admin';
import SaveIcon from '@material-ui/icons/Save';
import { useFormState } from 'react-final-form';

import { parseCreateProfileDiffForm } from '../../../lib/utils/profile';

const CreateBtn = ({
  form, customLoad, customProfile, errorHomeCom,
}) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const { errors, dirtyFields, values } = useFormState();
  const recordContext = useRecordContext();
  const dataProvider = useDataProvider();
  const [enabledBtn, setEnabledBtn] = useState(false);

  const zero = 0;
  useEffect(() => {
    const noError = Object.entries(errors).length === zero;
    const isEnabled = noError && !errorHomeCom;
    setEnabledBtn(isEnabled);
  }, [errors, errorHomeCom]);

  const create = () => {
    customLoad(true);
    const send = parseCreateProfileDiffForm(dirtyFields, values, recordContext);
    return enabledBtn && dataProvider
      .create('profiles', { data: { ...send } })
      .then((res) => {
        const { userId } = form;
        customLoad(false);
        redirect(`/users/${userId}/profile`);
        customProfile(res?.data);
        notify('profile created');
      })
      .catch((error) => {
        customLoad(false);
        notify(`Error: ${error.message}`, 'warning');
      });
  };

  return (
    <Button
      variant="contained"
      color="primary"
      label="Create profile"
      onClick={create}
      disabled={!enabledBtn}
      startIcon={<SaveIcon />}
    />
  );
};
export default CreateBtn;
