import React from 'react';
import { Route } from 'react-router-dom';

import PromotionOrdersReport from './resources/promotions/PromotionOrdersReport';
import UploadCsv from './resources/products/UploadCsv';
import JobsList from './resources/products/JobsList';
import JobDetail from './resources/products/JobDetail';
import Syndication from './resources/products/Syndication';
import MediaProduct from './resources/mediaProduct/index';
import ShufflePage from './resources/shuffle/index';
import BlackList from './resources/blackList/BlackList';
import UnsubList from './resources/unsubs/UnsubList';

export default [
  <Route exact path="/blacklist" component={BlackList} />,
  <Route exact path="/unsub" component={UnsubList} />,
  <Route exact path="/promotions/orders/report" component={PromotionOrdersReport} />,
  <Route exact path="/shuffle" component={ShufflePage} />,
  <Route exact path="/products/uploadCsv" component={UploadCsv} />,
  <Route exact path="/products/jobsList" component={JobsList} />,
  <Route exact path="/products/job/:jobId" component={JobDetail} />,
  <Route exact path="/products/job/" component={JobDetail} />,
  <Route exact path="/products/media/" component={MediaProduct} />,
  <Route exact path="/products/syndication" component={Syndication} />,
];
