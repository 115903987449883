import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  FormWithRedirect,
  SelectInput,
  TextInput,
  useDataProvider,
} from 'react-admin';
import { useNotify } from 'ra-core';
import Box from '@material-ui/core/Box';
import SaveIcon from '@material-ui/icons/Save';
import { Toolbar } from '@material-ui/core';

import { promotionFormStyles } from './promotionFormStyles';
import { RADIX_TEN } from './constants';

const PromotionRouter = (props) => {
  const { promotionUuid } = props;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [data, setData] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [number, setNumber] = useState(undefined);
  const [error, setError] = useState(false);
  const classes = promotionFormStyles();

  const selectChoices = [
    { id: true, name: 'Enabled' },
    { id: false, name: 'Disabled' },
  ];

  const createOrUpdate = (input, isCreate) => {
    dataProvider.update('router', {
      input,
    })
      .then(() => {
        if (!isCreate) {
          notify('Router configuration updated successfully.');
        }
      })
      .catch((err) => {
        notify(err?.message, 'error');
      });
  };

  useEffect(() => {
    dataProvider.getOne('router', {
      input: {
        id: promotionUuid,
      },
    })
      .then((res) => {
        const { data: queryData } = res;

        setData(queryData);
      })
      .catch((err) => {
        if (err?.message === 'Not found') {
          const zero = 0;
          const input = {
            isRouterActive: false,
            id: promotionUuid,
            postEvaluationsCleanedCount: zero,
            postEvaluationsToDivert: zero,
          };
          createOrUpdate(input, false);
        } else {
          notify(err?.message, 'error');
        }
      });
  }, []);

  useEffect(() => {
    setStatus(data?.isRouterActive);
    setNumber(data?.postEvaluationsToDivert);
  }, [data]);

  const record = useMemo(() => ({
    id: 'router',
    isRouterActive: data?.isRouterActive ?? null,
    postEvaluationsCleanedCount: data?.postEvaluationsCleanedCount ?? null,
    postEvaluationsToDivert: data?.postEvaluationsToDivert ?? null,
  }), [data]);

  const validateIntegerNumber = (value) => {
    const hexRegex = /^\d+?$/;
    if (!value) {
      setError(true);
      return 'Required';
    }
    if (value && !hexRegex.test(value)) {
      setError(true);
      return 'Please insert a number without decimals';
    }
    setError(false);
    return null;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (error) {
      notify("Can't save, check for form errors", 'warning');
    } else {
      const zero = 0;
      const input = {
        id: promotionUuid,
        isRouterActive: status,
        postEvaluationsToDivert: status ? number : zero,
      };

      createOrUpdate(input, false);
    }
  };

  return (
    <div className={classes.formControl}>
      <FormWithRedirect
        redirect={false}
        record={record}
        {...props}
        render={() => (
          <form style={{ width: '100%' }} onSubmit={handleSubmit}>
            <Box className={classes.sectionWrapper}>
              <SelectInput
                source="isRouterActive"
                style={{ width: '25%', maxWidth: '100%' }}
                choices={selectChoices}
                label="Status"
                onChange={e => setStatus(e.target.value)}
              />
            </Box>
            {status === true && (
              <>
                <Box className={classes.sectionWrapper}>
                  <TextInput
                    source="postEvaluationsCleanedCount"
                    label="Evaluations clean count"
                    style={{ width: '25%', maxWidth: '100%' }}
                    disabled
                  />
                </Box>
                <Box className={classes.sectionWrapper}>
                  <TextInput
                    source="postEvaluationsToDivert"
                    style={{ width: '25%', maxWidth: '100%' }}
                    validate={validateIntegerNumber}
                    onChange={e => setNumber(
                      e.target.value ? parseInt(e.target.value, RADIX_TEN) : null,
                    )}
                  />
                </Box>
              </>
            )}
            <Toolbar>
              <Box display="flex">
                <Button
                  label="Save"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={error}
                  startIcon={<SaveIcon />}
                />
              </Box>
            </Toolbar>
          </form>
        )}
      />
    </div>
  );
};

export default PromotionRouter;
