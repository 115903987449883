import React, { useState, useLayoutEffect } from 'react';
import {
  TextInput,
  ListButton,
  SelectInput,
  AutocompleteInput,
  useRecordContext,
  useDataProvider,
  AutocompleteArrayInput, FormDataConsumer, BooleanInput,
} from 'react-admin';

import { useForm, useFormState } from 'react-final-form';

import { makeStyles } from '@material-ui/core/styles';

import {
  Radio,
  Box,
} from '@material-ui/core';

import RichTextInput from 'ra-input-rich-text';
import {
  NutriScoreOptionField,
  nutriscoreOptions,
  nutritionalOptions,
  validateFloatWithOneDecimal,
  validateIntegerNumber,
} from '../../lib/utils/products';
import LabeledTextField from '../../lib/components/LabeledInput';
import UploadImage from '../../lib/components/UploadImage';

const useStyles = makeStyles(() => ({
  override: {
    '& > label': {
      marginLeft: 0,
      marginRight: 0,
    },
    '& .MuiRadio-root': {
      color: '#3FA1EA',
    },
    '& .MuiIconButton-colorPrimary': {
      color: '#3FA1EA',
    },
  },
  hidden: {
    display: 'none',
  },
}));

const Form = (props) => {
  const form = useForm();
  const formState = useFormState();
  const recordContext = useRecordContext(props);
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const { isEdit } = props;
  const [nutritionalInfo, setNutritionalInfo] = useState('');
  const [allergensLeft, setAllergensLeft] = useState(undefined);
  const [allergensRight, setAllergensRight] = useState(undefined);
  const [radioValues, setRadioValues] = useState(undefined);
  const [categories, setCategories] = useState([{}]);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [labels, setLabels] = useState([{}]);
  const [labelsLoading, setLabelsLoading] = useState(true);
  const globalFont = { fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif' };

  useLayoutEffect(() => {
    dataProvider.getList('allergens', {
      pagination: {
        page: 1,
        perPage: 14,
      },
    }).then((data) => {
      const zero = 0;
      const half = 7;
      setAllergensLeft(data?.data.slice(zero, half));
      setAllergensRight(data?.data.slice(half));

      setRadioValues(isEdit ? recordContext?.allergens : {
        milk: 'NA',
        soybeans: 'NA',
        peanuts: 'NA',
        celery: 'NA',
        eggs: 'NA',
        molluscs: 'NA',
        'tree nuts': 'NA',
        fish: 'NA',
        mustard: 'NA',
        'sesame seeds': 'NA',
        'sulfur dioxide and sulfits': 'NA',
        lupine: 'NA',
        crustaceans: 'NA',
        gluten: 'NA',
      });
    });

    dataProvider.getList('categories')
      .then((data) => {
        setCategories(data?.data);
        setCategoriesLoading(false);
      });

    const labelsPage = 1;
    const labelsPerPage = 1000;
    dataProvider.getList('labels', {
      pagination: {
        page: labelsPage,
        perPage: labelsPerPage,
      },
    })
      .then((data) => {
        setLabels(data?.data);
        setLabelsLoading(false);
      });
  }, []);

  useLayoutEffect(() => {
    form.change('radioValues', radioValues);
  }, [radioValues]);

  const changeRadioValue = (name, value) => {
    setRadioValues({
      ...radioValues,
      [name]: value,
    });
  };

  return (
    <>
      <ListButton style={{ width: '60px' }} />
      <h3 style={{ ...globalFont }}>Product Info</h3>
      <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
        <Box flex={2} style={{ alignSelf: 'center' }}>
          <TextInput disabled={isEdit} source="ean" id="eanInput" style={{ width: '98%' }} />
        </Box>
        <Box flex={1} style={{ alignSelf: 'center' }} />
        <Box flex={3} style={{ alignSelf: 'center' }}>
          {isEdit && (
            <FormDataConsumer>
              {({ formData }) => (
                <BooleanInput label={formData.active ? 'ACTIVE' : 'INACTIVE'} source="active" />
              )}
            </FormDataConsumer>
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
        <Box flex={6} style={{ alignSelf: 'center' }}>
          <TextInput source="name" id="nameInput" style={{ width: '97%' }} />
        </Box>
        <Box flex={2} style={{ alignSelf: 'center' }}>
          <TextInput source="packaging" style={{ width: '97%' }} />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
        <Box flex={1} style={{ alignSelf: 'center' }}>
          <TextInput source="brand" style={{ width: '350px' }} />
        </Box>
      </Box>
      <h3 style={{ ...globalFont }}>Product Image</h3>
      <FormDataConsumer>
        {({ formData }) => (
          <UploadImage
            {...props}
            id={formData.uuid}
            originalUrl={formData.image}
            notInput
            source="image"
            accept="image/jpeg,image/png,image/jpg"
            maxSize={2000000}
            placeholder={<p>Drop your image here(Max size 2M)</p>}
            resize={{ widthResize: 300, heightResize: 300 }}
            previewWidthAndHeight={{ width: '200', height: '200' }}
          />
        )}
      </FormDataConsumer>
      <h3 style={{ ...globalFont }}>Other Product Information</h3>
      <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
        <Box flex={1} style={{ alignSelf: 'center' }}>
          <AutocompleteInput
            source="category"
            choices={categories}
            loading={categoriesLoading}
            translateChoice={false}
            allowEmpty
            emptyText="(None)"
            resettable
          />
        </Box>
        <Box flex={1} style={{ alignSelf: 'center' }}>
          <AutocompleteArrayInput
            source="labels"
            choices={labels}
            loading={labelsLoading}
            translateChoice={false}
            optionValue="name"
            resettable
          />
        </Box>
      </Box>
      <RichTextInput source="description" />
      <h3 style={{ ...globalFont }}>Nutritional information</h3>
      <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
        <Box flex={1} style={{ marginRight: '10px' }}>
          <SelectInput
            fullWidth
            source="nutritionalInfo"
            label="Nutritional group"
            choices={nutritionalOptions}
            onChange={(event) => { setNutritionalInfo(event.target.value); }}
          />
        </Box>
        <Box flex={1} style={{ marginRight: '10px', minWidth: '220px' }} />
        <Box flex={1} style={{ marginRight: '10px', minWidth: '220px' }} />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box flex={1} style={{ marginRight: '10px', maxWidth: '100px' }}>
          <SelectInput
            fullWidth
            source="nutriscore"
            choices={nutriscoreOptions}
            optionText={<NutriScoreOptionField />}
            disabled={nutritionalInfo === 'no'}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
        <LabeledTextField
          labelAdornment="Kcal"
          source="contents.calories"
          label="Calories"
          validate={validateIntegerNumber}
          validateOnBlur
          disabled={nutritionalInfo === 'no'}
        />
        <LabeledTextField
          labelAdornment="g"
          source="contents.grasSat"
          label="Saturated Fats"
          validate={validateFloatWithOneDecimal}
          validateOnBlur
          disabled={nutritionalInfo === 'no'}
        />
        <LabeledTextField
          labelAdornment="g"
          source="contents.sucres"
          label="Sugars"
          validate={validateFloatWithOneDecimal}
          validateOnBlur
          disabled={nutritionalInfo === 'no'}
        />
        <LabeledTextField
          labelAdornment="g"
          source="contents.fibres"
          label="Fibers"
          validate={validateFloatWithOneDecimal}
          validateOnBlur
          disabled={nutritionalInfo === 'no'}
        />
        <LabeledTextField
          labelAdornment="g"
          source="contents.protein"
          label="Proteins"
          validate={validateFloatWithOneDecimal}
          validateOnBlur
          disabled={nutritionalInfo === 'no'}
        />
        <LabeledTextField
          labelAdornment="g"
          source="contents.sodium"
          label="Sodium"
          validate={validateFloatWithOneDecimal}
          validateOnBlur
          disabled={nutritionalInfo === 'no'}
        />
      </Box>
      <RichTextInput
        source="compositionText"
        label="Ingredients / Composition"
      />
      <h3 style={{ ...globalFont }}>Allergens</h3>
      {formState?.errors?.radioValues && (
        <div style={{
          color: 'red', marginLeft: '15px', height: '20px', marginTop: '-10px', marginBottom: '20px', ...globalFont,
        }}
        >
          {formState?.errors?.radioValues}
        </div>
      )}
      {allergensLeft && allergensRight && (
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          justifyContent="space-evenly"
          style={{ width: '100%', gap: '0 30px', margin: '10px 0' }}
        >
          <Box
            display="flex"
            flexDirection="column"
            flexWrap="nowrap"
          >
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="nowrap"
              style={{ gap: '10px 0', textAlign: 'center', marginBottom: '10px' }}
            >
              <Box flex={1} style={{ ...globalFont }} />
              <Box flex={1} style={{ ...globalFont }}>Yes, it contains</Box>
              <Box flex={1} style={{ ...globalFont }}>No, it doesnt contain</Box>
              <Box flex={1} style={{ ...globalFont }}>Contains traces</Box>
              <Box flex={1} style={{ ...globalFont }}>No information</Box>
            </Box>
            {allergensLeft.map(item => (
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="nowrap"
                alignItems="center"
                style={{ gap: '10px 0' }}
                key={item.name}
              >
                <Box flex={1} style={{ ...globalFont }}>{item.code}</Box>
                <Box flex={1} display="flex" justifyContent="center" className={classes.override}>
                  <Radio
                    color="primary"
                    onClick={() => changeRadioValue(item.code, 'YES')}
                    checked={radioValues?.[item.code] === 'YES'}
                  />
                </Box>
                <Box flex={1} display="flex" justifyContent="center" className={classes.override}>
                  <Radio
                    color="primary"
                    onClick={() => changeRadioValue(item.code, 'NO')}
                    checked={radioValues?.[item.code] === 'NO'}
                  />
                </Box>
                <Box flex={1} display="flex" justifyContent="center" className={classes.override}>
                  <Radio
                    color="primary"
                    onClick={() => changeRadioValue(item.code, 'TRACES')}
                    checked={radioValues?.[item.code] === 'TRACES'}
                  />
                </Box>
                <Box flex={1} display="flex" justifyContent="center" className={classes.override}>
                  <Radio
                    color="primary"
                    onClick={() => changeRadioValue(item.code, 'NA')}
                    checked={radioValues?.[item.code] === 'NA'}
                  />
                </Box>
              </Box>
            ))}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            flexWrap="nowrap"
          >
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="nowrap"
              style={{ gap: '10px 0', textAlign: 'center', marginBottom: '10px' }}
            >
              <Box flex={1} style={{ ...globalFont }} />
              <Box flex={1} style={{ ...globalFont }}>Yes, it contains</Box>
              <Box flex={1} style={{ ...globalFont }}>No, it doesnt contain</Box>
              <Box flex={1} style={{ ...globalFont }}>Contains traces</Box>
              <Box flex={1} style={{ ...globalFont }}>No information</Box>
            </Box>
            {allergensRight.map(item => (
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="nowrap"
                alignItems="center"
                style={{ gap: '10px 0' }}
                key={item.name}
              >
                <Box flex={1} style={{ ...globalFont }}>{item.code}</Box>
                <Box flex={1} display="flex" justifyContent="center" className={classes.override}>
                  <Radio
                    color="primary"
                    onClick={() => changeRadioValue(item.code, 'YES')}
                    checked={radioValues?.[item.code] === 'YES'}
                  />
                </Box>
                <Box flex={1} display="flex" justifyContent="center" className={classes.override}>
                  <Radio
                    color="primary"
                    onClick={() => changeRadioValue(item.code, 'NO')}
                    checked={radioValues?.[item.code] === 'NO'}
                  />
                </Box>
                <Box flex={1} display="flex" justifyContent="center" className={classes.override}>
                  <Radio
                    color="primary"
                    onClick={() => changeRadioValue(item.code, 'TRACES')}
                    checked={radioValues?.[item.code] === 'TRACES'}
                  />
                </Box>
                <Box flex={1} display="flex" justifyContent="center" className={classes.override}>
                  <Radio
                    color="primary"
                    onClick={() => changeRadioValue(item.code, 'NA')}
                    checked={radioValues?.[item.code] === 'NA'}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Form;
