import {
  email,
  maxLength, minLength, number,
  regex,
  required,
} from 'react-admin';

const maxInputCharacterLength = 50;
const maxEmailCharacterLength = 254;
const phoneLength = 8;
const validatePatternLetters = regex(/^[a-zA-ZàâäéèêëîïôöùûüÿçÇ\s'-]+$/);

const UserValidation = {
  validateName: [required(), maxLength(maxInputCharacterLength), validatePatternLetters],
  validateLastName: [required(), maxLength(maxInputCharacterLength), validatePatternLetters],
  validateUsername: [required(), email(), maxLength(maxEmailCharacterLength)],
  validateActive: [required()],
  validatePhone: [
    number(),
    minLength(phoneLength, 'Must be an 8 digits number'),
    maxLength(phoneLength, 'Must be an 8 digits number'),
  ],
};

export default UserValidation;
