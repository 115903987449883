import React from 'react';
import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';

import MultipleProductMedia from '../../lib/components/MultipleProductMedia';

const ShowActions = () => (
  <div>
    <MultipleProductMedia placeholder="Drop some pictures to upload, or click here to select them (max. 200 files)" />
  </div>
);
const MediaProduct = () => (
  <Card>
    <CardContent>
      <ShowActions />
    </CardContent>
  </Card>
);

export default MediaProduct;
