import React, { useEffect, useState } from 'react';
import {
  useDataProvider, useNotify, Button, useRecordContext,
} from 'react-admin';
import SaveIcon from '@material-ui/icons/Save';
import { useFormState } from 'react-final-form';
import { useRefresh } from 'ra-core';

import { parseEditUserDiffForm } from '../../../lib/utils/profile';


const EditUserBtn = ({ customLoad, customProfile }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const recordContext = useRecordContext();
  const {
    errors, dirty, dirtyFields, values,
  } = useFormState();
  const [enabledBtn, setEnabledBtn] = useState(false);
  const zero = 0;

  useEffect(() => {
    const noError = Object.entries(errors).length === zero;
    const isEnabled = noError && dirty;
    setEnabledBtn(isEnabled);
  }, [dirty, errors]);

  const edit = () => {
    customLoad(true);
    const send = parseEditUserDiffForm(dirtyFields, values, recordContext);
    return enabledBtn && dataProvider
      .update('users', { data: { ...send } })
      .then((res) => {
        customLoad(false);
        customProfile(res?.data);
        refresh();
        notify('User updated');
      })
      .catch((error) => {
        customLoad(false);
        notify(`Error: ${error.message}`, 'warning');
      });
  };

  return (
    <Button
      variant="contained"
      color="primary"
      label="Edit User"
      style={{ maxWidth: '140px' }}
      onClick={edit}
      disabled={!enabledBtn}
      startIcon={<SaveIcon />}
    />
  );
};
export default EditUserBtn;
