import { GET_CLIENT_SECRET_PUBLIC, OAUTH_API_URL } from './utils';

const urlOAuth = OAUTH_API_URL();
const clientSecret = GET_CLIENT_SECRET_PUBLIC();
const getToken = () => fetch(urlOAuth, {
  method: 'post',
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  body: new URLSearchParams({
    grant_type: 'client_credentials',
    client_id: process.env.REACT_APP_CRED_CLIENT_ID_PUBLIC,
    client_secret: clientSecret,
    scope: 'read:legacy write:legacy',
  }),
})
  .then(response => response.json())
  .then(res => res)
  .catch((error) => {
    throw error;
  });

export default getToken;
