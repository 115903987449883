import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Datagrid, TextField, useTranslate } from 'react-admin';
import { Box } from '@material-ui/core';
import keyBy from 'lodash/keyBy';
import client from '../../providers/client';
import BULK_BATCH_JOBS_LIST from '../../providers/queries/bulkBatchJobsList';
import UseInterval from '../../lib/hooks/UseInterval';

const zero = 0;
const complete = 1;
const fullLoad = 90;
const base = 10;
const baseRefresh = 2000;
const isJobDeleted = results => (results?.length === zero);
const isJobFinish = (status, progress) => (status === 'FINISHED' || progress === complete);

const globalFont = { fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif' };

const ErrorsField = ({ record }) => (
  <>
    <ul style={{ listStyle: 'none', marginTop: '0', marginLeft: '-35px' }}>
      <li key="num_err"><b>{record.errors.length}</b> errors:</li>
      {record.errors.map(item => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  </>

);
const ShowBatchJobStatus = ({
  jobId, setEndWithError, STATUS, setEnd, setProgress,
}) => {
  const translate = useTranslate();
  const [refreshIdle, setRefreshIdle] = useState(null);
  const [jobProgress, setJobProgress] = useState(zero);
  const [eanProcessed, setEanProcessed] = useState([]);
  const [eanInProgress, setEanInProgress] = useState([]);
  const [eanInError, setEanInError] = useState([]);
  const [sort, setSort] = useState({ field: 'id', order: 'ASC' });
  const getData = () => {
    const query = gql`${BULK_BATCH_JOBS_LIST}`;
    const ids = [jobId];
    const variables = {
      filter: { ids },
      filterParts: { parentId: jobId },
      pagination: { limit: 20000, page: 1 },
    };
    client
      .query({ query, variables })
      .then((res) => {
        const { data } = res;

        if (data === undefined) {
          const { errors } = res;

          if (errors !== undefined) {
            setRefreshIdle(null);
            const errorMsg = errors[zero].message;
            return setEndWithError(errorMsg, STATUS.PROCESSING_FILE);
          }
        }

        const { batchJobs, bulkJobParts } = data;
        const { results } = batchJobs;

        if (isJobDeleted(results)) {
          return setEndWithError(translate('products.uploads.error.deleted'), STATUS.PROCESSING_FILE);
        }

        const jobData = results[zero];
        const { status, progress } = jobData;
        const parts = bulkJobParts?.results;
        const parsedLog = parts;
        const sumProg = Math.round(parseFloat(progress) * fullLoad);
        const processed = parsedLog.filter(item => item.status === 'FINISHED');
        const inProgress = parsedLog.filter(item => item.status === 'CREATED');
        const inError = parsedLog.filter(item => item.errors.length > zero);
        setEanProcessed(processed);
        setEanInProgress(inProgress);
        setEanInError(inError);
        setJobProgress(sumProg);

        if (isJobFinish(status, progress)) {
          setRefreshIdle(null);
          return setEnd();
        }

        return true;
      })
      .catch();
  };

  UseInterval(() => {
    getData();
  }, refreshIdle);

  useEffect(() => {
    getData();
    setRefreshIdle(baseRefresh);
  }, []);

  useEffect(() => {
    setProgress(jobProgress + base);
  }, [jobProgress]);
  return (
    <Box style={{ width: '100%', ...globalFont }}>
      <hr />
      {refreshIdle && (
        <div>
          <div style={{ ...globalFont }}>
            <b>
              {translate('products.uploads.jobs.title')}{eanProcessed.length + eanInProgress.length}
              {translate('products.uploads.jobs.title_2')}
            </b>
            <hr />
          </div>
          <div><b>{translate('products.uploads.jobs.titleProgress')}</b>: {eanInProgress.length}</div>
          <hr />
        </div>
      )}
      <div><b>{translate('products.uploads.jobs.titleComplete')}</b>: {eanProcessed.length}</div>
      <div style={{ color: 'green' }}><b>{translate('products.uploads.jobs.titleCompleteOK')}</b>: {eanProcessed.length - eanInError.length}</div>
      {eanInError.length > zero
        && (
          <>
            <div style={{ color: 'red' }}><b>{translate('products.uploads.jobs.titleError')}</b>: {eanInError.length}</div>
            <hr />
            <Box>
              <Datagrid
                data={keyBy(eanInError, 'id')}
                ids={eanInError?.map(({ id }) => id)}
                currentSort={sort}
                setSort={(field, order) => setSort({ field, order })}
              >
                <TextField source="key" label="EAN::Shuffle type" sortable={false} />
                <TextField source="status" sortable={false} />
                <ErrorsField source="errors" />
              </Datagrid>
            </Box>
          </>
        )
      }
    </Box>
  );
};

export default ShowBatchJobStatus;
