import moment from 'moment';

const base10 = 10;
const convert = 1000;
const isExpired = limit => Date.now() >= parseInt(limit, base10);
const calculateExpirationTime = seconds => Date.now() + (parseInt(seconds, base10) * convert);

const parseDateQuery = (inputDate) => {
  const formatInput = 'DD/MM/YYYY';
  const date = moment(inputDate, formatInput);
  const formatWithSeparator = 'YYYY-MM-DD';
  if (!date.isValid()) {
    return null;
  }
  return date.format(formatWithSeparator);
};

const parseDateFromQuery = (inputDate) => {
  const formatInput = 'YYYY-MM-DD';
  const date = moment(inputDate, formatInput);
  const formatWithSeparator = 'DD/MM/YYYY';
  if (!date.isValid()) {
    return null;
  }
  return date.format(formatWithSeparator);
};

export {
  isExpired, calculateExpirationTime, parseDateQuery, parseDateFromQuery,
};
