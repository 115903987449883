import React from 'react';
import { Pagination, useListContext, useTranslate } from 'react-admin';
import {
  Toolbar, Button, Box, Typography,
} from '@material-ui/core';


const PaginationComment = (props) => {
  const {
    total, loading, page, setPage,
  } = useListContext();
  const translate = useTranslate();
  const initial = 1;
  const maxResults = 10000;

  if (!loading) {
    if (total < initial) {
      return (
        <Box textAlign="center" m={1}>
          <Typography variant="h4" paragraph>
            {translate('comment.list.zero')}
          </Typography>
        </Box>
      );
    }
    if (total < maxResults) {
      const ten = 10;
      const fifty = 50;
      const oneHundred = 100;
      const twoHundred = 200;
      return (
        <Pagination rowsPerPageOptions={[ten, fifty, oneHundred, twoHundred]} {...props} />
      );
    }
    return (
      <Toolbar style={{ display: 'flex', justifyContent: 'end' }}>
        {page > initial && <Button onClick={() => setPage(page - initial)}>{translate('comment.list.prev')}</Button>}
        {<Button onClick={() => setPage(page + initial)}>{translate('comment.list.next')}</Button>}
      </Toolbar>
    );
  }
  return null;
};

export default PaginationComment;
