import React, { Fragment, useEffect, useState } from 'react';
import {
  ImageInput, SimpleForm,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import UpdateProductImage from './UpdateProductImage';

const useStyles = makeStyles(() => ({
  dropZone: {
    '& div[data-testid="dropzone"]': {
      minHeight: '70px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}));

const MultipleProductMedia = (props) => {
  const {
    accept, maxSize, label, placeholder,
  } = props;
  const classes = useStyles();
  const [images, setImages] = useState([]);
  const [success, setSuccess] = useState([]);
  const [error, setError] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalOk, setTotalOk] = useState(0);
  const [totalKo, setTotalKo] = useState(0);
  const maxFiles = 200;

  const resizeImage = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 300;
        canvas.height = 300;
        ctx.drawImage(img, 0, 0, 300, 300);
        canvas.toBlob((blob) => {
          resolve({ file: blob, ean: file?.name.split('.')[0] });
        }, file.type);
      };
      img.src = event.target.result;
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

  const handleImageUpload = (e) => {
    const filesI = e;
    const imageArray = Array.from(filesI).slice(0, maxFiles);
    setTotal(imageArray.length);
    const resizedImagesPromises = imageArray.map(file => resizeImage(file));

    Promise.all(resizedImagesPromises).then((resizedImages) => {
      setImages(resizedImages);
    });
  };
  useEffect(() => {
    setTotalOk(success.length);
    setTotalKo(error.length);
  }, [error, success]);
  return (
    <Fragment>
      <div>
        {images.length === 0 && (
          <div>
            <SimpleForm toolbar={null}>
              <ImageInput
                source="imagePreview"
                maxSize={maxSize}
                multiple
                label={label || ''}
                accept={accept}
                onChange={img => handleImageUpload(img)}
                placeholder={placeholder}
                className={classes.dropZone}
              >
                <div />
              </ImageInput>
            </SimpleForm>
          </div>
        )}
        {images.length > 0 && (
          <div>
            <Typography>
              Total files selected: {total}
            </Typography>
            <Typography>
              Files processing: {total - (totalKo + totalOk)}
            </Typography>
            <Typography>
              Total products with error: {totalKo}
            </Typography>
            <Typography>
              Total products successfully updated: {totalOk}
            </Typography>
          </div>
        )}
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {images.map((image, index) => (
            <UpdateProductImage
              key={image?.ean}
              file={image.file}
              ean={image?.ean}
              setOk={setSuccess}
              setFail={setError}
              fail={error}
              okList={success}
            />
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default MultipleProductMedia;
