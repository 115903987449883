import React, { useState } from 'react';
import {
  useDataProvider, useNotify, Button, useRecordContext, useTranslate, useRedirect,
} from 'react-admin';


import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconSave from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import IconCancel from '@material-ui/icons/Cancel';
import { Typography } from '@material-ui/core';


const DeleteBtn = ({ customLoad }) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const recordContext = useRecordContext();
  const [showDialog, setShowDialog] = useState(false);

  const deleteUser = () => {
    customLoad(true);
    const { id } = recordContext;
    return showDialog && dataProvider
      .delete('users', { id })
      .then(() => {
        customLoad(false);
        notify('User deleted successfully');
        redirect('/users');
      })
      .catch((error) => {
        customLoad(false);
        notify(`Error: ${error.message}`, 'warning');
      });
  };
  return (
    <>
      <Dialog fullWidth open={showDialog} aria-label="Remove user">
        <DialogContent>
          <Typography> {translate('user.edit.deleteWarning')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            label="ra.action.remove"
            onClick={deleteUser}
          >
            <IconSave />
          </Button>
          <Button onClick={() => setShowDialog(false)} label="ra.action.cancel">
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        variant="contained"
        color="red"
        label="Delete User"
        style={{ maxWidth: '140px', backgroundColor: 'red' }}
        onClick={() => setShowDialog(true)}
        startIcon={<IconSave />}
      />
    </>
  );
};
export default DeleteBtn;
