import React from 'react';
import {
  Create,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';
import UserValidation from './UserValidation';

const UserCreateToolbar = props => (
  <Toolbar {...props}>
    <SaveButton
      label="Create User"
      redirect="edit"
      submitOnEnter
      data-testid="user-create-submit"
    />
  </Toolbar>
);

const UserCreate = ({ permissions, ...props }) => (
  <Create {...props}>
    <SimpleForm
      toolbar={<UserCreateToolbar />}
      data-testid="user-create-form"
    >
      <TextInput
        source="username"
        fullWidth
        validate={UserValidation.validateUsername}
        resettable
      />
      <TextInput
        source="name"
        fullWidth
        validate={UserValidation.validateName}
        resettable
      />
      <TextInput
        fullWidth
        source="lastName"
        validate={UserValidation.validateLastName}
        resettable
      />
    </SimpleForm>
  </Create>
);

export default UserCreate;
