import {
  Edit,
  SimpleForm,
} from 'react-admin';
import React from 'react';
import { useNotify, useRefresh } from 'ra-core';

import UserTitle from './UserTitle';
import UserDetailTabForm from './UserDetailTabForm';

const UserDetailTab = (props) => {
  const redirect = (basePath, id, data) => `${basePath}/${data.id}`;
  const refresh = useRefresh();
  const notify = useNotify();

  const onSuccessActions = () => {
    notify('User updated successfully', 'success');
    refresh();
  };

  return (
    <Edit mutationMode="pessimistic" onSuccess={onSuccessActions} title={<UserTitle />} {...props}>
      <SimpleForm redirect={redirect} toolbar={null}>
        <UserDetailTabForm />
      </SimpleForm>
    </Edit>
  );
};

export default UserDetailTab;
